import '../App.css';

import Mobile from "../components/Mobile";
import BiggerScreen from "../components/BiggerScreen";
import React from "react";
import NavBarMobile from '../components/NavBarMobile'
import UpperText from '../components/UpperText'
import WatchPalette from '../components/WatchPalette'
import WatchFaceButton from "../components/WatchFaceButton";
import BlockFour from "../components/BlockFour";
import Footer from "../components/Footer";
import Tutorial_Video from "../components/Tutorial Video";

function MainPage() {


    return (
        <div className={"scroll"}>
            <div id="pre-loading-img"></div>
            <div id="background-div" className="background-color">
                <NavBarMobile/>
                <div className="container-fluid pt-4 pb-2">
                    <Mobile/>
                    <div className="d-none d-lg-block container">
                        <BiggerScreen/>
                    </div>
                </div>
                <UpperText/>
                <WatchPalette/>
                <WatchFaceButton/>
                <Tutorial_Video embedId="SECjCi66g80" />
                <BlockFour/>
                <Footer/>
            </div>
        </div>
    );

}

export default MainPage;
