import React from 'react';

function NavBarMobile() {
    return (
        <div className="container d-lg-none" style={{
            background: 'rgba(7, 1, 13, 0.27)',
            height: '90px',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            paddingTop: '20px',
            backdropFilter: 'blur(25px)'
        }}>
            <div className="ps-2 pe-2 d-flex align-items-center justify-content-between">
                <img src="/static/icon.webp" style={{width: '55px', height: '55px', borderRadius: '16px'}} alt="icon"/>
                <h1 style={{
                    fontSize: '19px',
                    fontFamily: 'Comfortaa, cursive',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    lineHeight: '122.69%',
                    letterSpacing: '-0.005em',
                    color: '#FFFFFF'
                }}>
          Watch Faces Gallery ®️
        </h1>
            </div>
        </div>
    );
}

export default NavBarMobile;