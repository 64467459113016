
import GlassEffect from "./Tags";
import Container from "./Container";

function WatchPalette() {


    return (
        <div className="container-fluid">
            <div className="pt-4 row justify-content-center text-center">
                <div className="col col-lg-12" style={{overflow: 'hidden', maxWidth: '100%'}}>
                    <div className="position-relative" id="watch-palette-div">
                        <div className="d-lg-none" id="watch-palette-mobile">

                            <div className="glass-container-mobile">
                                <GlassEffect text={"TOP Pick"} mobile={true}/>
                                <GlassEffect text={"Apple Style"} mobile={true}/>
                            </div>
                            <div className="glass-container-mobile">
                                <GlassEffect text={"Widgets"} mobile={true}/>
                                <GlassEffect isLast={true} text={"Rolex" } mobile={true}/>
                                <GlassEffect text={"Analog Style"} mobile={true}/>
                            </div>

                            <div className="glass-container-mobile">

                                <GlassEffect text={"Depth Effect"} mobile={true}/>
                                <GlassEffect text={"Love"} mobile={true}/>


                            </div>
                            <Container mobile={true}/>
                        </div>

                        <div className="d-none d-lg-block" id="watch-palette-desktop">
                            <div className="glass-container">
                                <GlassEffect text={"Retro"}/>
                                <GlassEffect text={"Apple Style"}/>
                                <GlassEffect text={"Widgets"}/>
                                <GlassEffect isLast={true} text={"Rolex"}/>
                                <GlassEffect text={"Analog Style"}/>
                                <GlassEffect text={"Depth Effect"}/>
                                <GlassEffect text={"Love"}/>

                            </div>
                            <Container/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WatchPalette;
