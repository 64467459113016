import React from 'react';
import './WatchFace.css';

const WatchFace = ({ img })  => {
    return (
        <div >
            <img src= {img} alt="Image Face" className="glass-image-watch" />
        </div>
    );
};

export default WatchFace;