import React from 'react';

function UpperText() {
    return (
        <div className="container mt-4 pt-4 mt-lg-1 pt-lg-0">
            <div className="row justify-content-center text-center">
                <div className="d-lg-none col py-0 my-0" style={{
                    fontFamily: 'Comfortaa',
                    fontStyle: 'normal',
                    fontSize: '25px',
                    color: '#FFFFFF',
                    fontWeight: 700
                }}>
                    <h2>Apple Watch Faces</h2><br/>
                    <h2>New Look is here!</h2>
                </div>
                <div className="w-100"></div>
                <div className="col-11 col-lg-12 mt-4 mt-lg-0" style={{
                    fontFamily: 'Comfortaa',
                    fontStyle: 'normal',
                    fontSize: '20px',
                    color: '#FFFFFF',
                    fontWeight: 300,
                    zIndex: 1
                }}>
          <span>
Unlock Your Style with Personalized Apple Watch Faces - Explore Our Extensive Collection!
              <br/>
Discover an Incredible Array of Apple Watch Faces and Complications, All in a Single App.
              <br/>
Get ready to fall in love with this Must-Have App for Apple Watch users.
          </span>
                    <span className="d-lg-none">
            Compatible with any Apple Watch series.
          </span>
                    <br/>
                    <span>
           Start customizing now!
          </span>
                </div>
            </div>
        </div>
    );
}

export default UpperText;
