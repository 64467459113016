import React from 'react';
import './GlassEffect.css';

const GlassEffect = ({ text, isLast,mobile }) => {

    const TextStyle = mobile ? 'white-text-mobile' : 'white-text';
    const containerClassName  = mobile ? 'glass-effect-mobile ' : 'glass-effect ';
    const background = isLast ? ' gold' : ' normal';
    return (
        <div className={containerClassName+background } >
            <h4 className={TextStyle}>{text}</h4>
        </div>
    );
};

export default GlassEffect;