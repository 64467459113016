import React from 'react';
import './privacy.css'
const PrivacyPolicy = () => {
    return (
        <div
className="background-color"
            id="background-div"
        >
            <a href="/" className="text-decoration-none">
                <div
                    className="container d-lg-none"
                    style={{
                        background: 'rgba(7, 1, 13, 0.27)',
                        height: '90px',
                        borderBottomLeftRadius: '30px',
                        borderBottomRightRadius: '30px',
                        paddingTop: '20px',
                        backdropFilter: 'blur(25px)',
                    }}
                >
                    <div className="ps-2 pe-2 d-flex align-items-center justify-content-between">
                        <img
                            src="/static/icon.webp"
                            style={{ width: '55px', height: '55px', borderRadius: '16px' }}
                            alt="Icon"
                        />
                        <span
                            style={{
                                fontSize: '19px',
                                fontFamily: 'Comfortaa, cursive',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                lineHeight: '122.69%',
                                letterSpacing: '-0.005em',
                                color: '#ffffff',
                            }}
                        >
              Watch Faces Gallery & Widgets
            </span>
                    </div>
                </div>
            </a>

            <div className="container" style={{ paddingTop: '15px', paddingBottom: '30px', color: 'white' }}>
                <h1 className="text-center fw-bold display-3">Privacy Policy</h1>

                <article id="post-13" className="post-13 page type-page status-publish hentry article-container">
                   <br/>




                    <div className="entry-content">




                        <p>Protecting your privacy is important to MetaTec Apps Ltd. This Privacy Policy explains how we
                            collect, use, and disclose information that we receive through our website (metatec.io) and
                            via email at <a href="mailto:info@metatec.io">info@metatec.io</a>. Please read this Privacy
                            Policy carefully to understand our practices regarding your information. By using our
                            website or contacting us via email, you acknowledge and consent to the terms of this Privacy
                            Policy.</p>


                        <p>Revisions to this Privacy Policy: The Privacy Policy in effect at the time your information
                            is collected applies to that information. We may update this Privacy Policy from time to
                            time, and if we make any material changes, we will notify you by posting the changes on our
                            website or by sending you an email or other notification.</p>


                        <p>Collection and Use of Information: We collect information to provide and improve our
                            services, respond to your inquiries, and enhance your experience with MetaTec Apps. The
                            types of information we collect include:</p>


                        <ol>
                            <li>Personal Information: When you contact us via email (<a
                                href="mailto:info@metatec.io">info@metatec.io</a>), we may collect personal information,
                                such as your name, email address, and any other information you provide voluntarily in
                                your message.
                            </li>


                            <li>Website Usage Information: When you visit our website (metatec.io), we may collect
                                certain information automatically, such as your IP address, browser type, operating
                                system, and pages you visit. This information helps us analyze trends, administer the
                                website, and improve our services.
                            </li>


                            <li>Cookies and Similar Technologies: We may use cookies and similar technologies on our
                                website to enhance your browsing experience and collect information about how you use
                                our website. You can manage your cookie preferences through your browser settings.
                            </li>


                            <li>Third-Party Analytics: We may use third-party analytics services, such as Google
                                Analytics, to collect and analyze website usage information. These services may use
                                cookies and similar technologies to track your interactions with our website. The
                                information generated by these services is used to evaluate and improve our website’s
                                performance and user experience.
                            </li>
                        </ol>


                        <p>Information Sharing and Disclosure: We do not sell, rent, or share your personal information
                            with third parties, except in the following circumstances:</p>


                        <ol>
                            <li>Service Providers: We may engage trusted third-party service providers to assist us in
                                operating our website and providing our services. These service providers have access to
                                your personal information only to perform tasks on our behalf and are obligated to keep
                                your information confidential.
                            </li>


                            <li>Legal Requirements: We may disclose your personal information if required to do so by
                                law or in response to a valid legal request, such as a court order or government
                                inquiry.
                            </li>
                        </ol>


                        <p>Data Security: We take reasonable measures to protect your personal information from
                            unauthorized access, use, or disclosure. However, please be aware that no method of data
                            transmission over the internet or electronic storage is 100% secure. Therefore, we cannot
                            guarantee the absolute security of your information.</p>


                        <p>Links to Third-Party Websites: Our website may contain links to third-party websites. We are
                            not responsible for the privacy practices or content of these websites. We encourage you to
                            review the privacy policies of those third-party websites before providing any personal
                            information.</p>


                        <p>Children’s Privacy: Our services are not intended for individuals under the age of 13. We do
                            not knowingly collect personal information from children under 13. If you believe that we
                            have inadvertently collected information from a child under 13, please contact us
                            immediately, and we will take steps to remove the information from our records.</p>


                        <p>Your Rights and Choices: You have certain rights regarding your personal information,
                            including the right to access, update, or delete your information. If you wish to exercise
                            any of these rights or have any questions or concerns about our privacy practices, please
                            contact us at <a href="mailto:info@metatec.io">info@metatec.io</a>.</p>


                        <p>Contact Us: If you have any questions or comments about this Privacy Policy or our privacy
                            practices, please contact us at <a href="mailto:info@metatec.io">info@metatec.io</a>.</p>


                        <p>Please note that this Privacy Policy applies only to the information collected through our
                            website (metatec.io) and via email at <a
                                href="mailto:info@metatec.io">info@metatec.io</a> and does not apply to any third-party
                            websites or services that may be linked to or mentioned on our website.</p>
                    </div>


                </article>
            </div>








        </div>
    );
};

export default PrivacyPolicy;
