
import WatchFace from "./WatchFace";
import f1 from '../static/Faces/1.png';
import f2 from '../static/Faces/2.png';
import f3 from '../static/Faces/3.png';
import f4 from '../static/Faces/4.png';
import f5 from '../static/Faces/5.png';
import f6 from '../static/Faces/6.png';
import f7 from '../static/Faces/7.png';
import f8 from '../static/Faces/8.png';
import f9 from '../static/Faces/9.png';
import f10 from '../static/Faces/10.png';
import f11 from '../static/Faces/11.png';
import f12 from '../static/Faces/12.png';
import f13 from '../static/Faces/13.png';
import f14 from '../static/Faces/14.png';
import f15 from '../static/Faces/15.png';
import f16 from '../static/Faces/16.png';
import f17 from '../static/Faces/17.png';
import f18 from '../static/Faces/18.png';
import f19 from '../static/Faces/19.png';
import f20 from '../static/Faces/20.png';
import f21 from '../static/Faces/21.png';


import './Container.css';

const Container = ({mobile}) => {
    const classname = mobile ? 'container-watch-face container-classname-mobile' : 'container-watch-face container-classname';
    const classnameReverce = mobile ? 'container-watch-face container-classname-reverse-mobile' : 'container-watch-face container-classname-reverse';

    return (
        <>
            <div className={classname}>


                <WatchFace img={f1}/>
                <WatchFace img={f2}/>
                <WatchFace img={f3}/>
                <WatchFace img={f4}/>
                <WatchFace img={f9}/>
                <WatchFace img={f6}/>
                <WatchFace img={f7}/>

            </div>

            <div className={classnameReverce}>
                <WatchFace img={f8}/>
                <WatchFace img={f9}/>
                <WatchFace img={f10}/>
                <WatchFace img={f11}/>
                <WatchFace img={f12}/>
                <WatchFace img={f13}/>
                <WatchFace img={f14}/>

            </div>
            <div className={classname}>
                <WatchFace img={f15}/>
                <WatchFace img={f16}/>
                <WatchFace img={f17}/>
                <WatchFace img={f18}/>
                <WatchFace img={f19}/>
                <WatchFace img={f20}/>
                <WatchFace img={f21}/>


            </div>

        </>

    );
};

export default Container;



