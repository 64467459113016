import React, {useEffect} from 'react';
const initFacebookPixel = () => {
    // eslint-disable-next-line no-unused-expressions
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', '1282200545916703');
    window.fbq('track', 'PageView', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
};
function FourthBlock() {
    useEffect(() => {
        initFacebookPixel();
    }, []);

    const install_lead = () => {
        window.fbq('track', 'Lead', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
    };

    return (
        <div className="container pt-3">
            <div className="row justify-content-center" >
                {/* Watch-arm Block */}
                <div className="col-12 col-lg-6 order-lg-2 row justify-content-center px-0" style={{ zIndex: 2 }}>
                    <div className="col text-center px-0" id="watch-arm-div">
                        <img src="" className="img-fluid" id="watch-arm-img" onClick={() => {window.location.href = 'https://apps.apple.com/app/apple-store/id1643342697?pt=125193385&ct=Watch%20Faces%20Website&mt=8';}}  alt="link"/>
                    </div>
                </div>

                {/* End Watch-arm Block */}

                {/* Bottom Bar */}
                <div className="col-12 order-lg-3 ps-3 pe-2 py-3" style={{ background: 'rgba(7, 1, 13, 0.27)', borderRadius: '30px', backdropFilter: 'blur(25px)' }}>
                    <div className="d-flex align-self-center justify-content-between justify-content-lg-center align-items-center">
                        <div className="d-flex align-items-center d-lg-none">
                            <img src="/static/icon.webp" style={{ width: '55px', height: '55px', borderRadius: '16px' }}  alt="icon"/>
                            <div className="ms-2 d-lg-none">
                                <span style={{ fontSize: '15px', fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 700, lineHeight: '122.69%', letterSpacing: '-0.005em', color: '#FFFFFF' }}>
                                    Watch Faces
                                </span><br />
                                <span style={{ fontSize: '15px', fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 700, lineHeight: '122.69%', letterSpacing: '-0.005em', color: '#FFFFFF' }}>
                                    Gallery
                                </span>
                            </div>
                            <span className="d-none d-lg-block" style={{ fontSize: '20px', fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 700, lineHeight: '122.69%', letterSpacing: '-0.005em', color: '#FFFFFF' }}>
                                Watch Faces Gallery ®️
                            </span>
                        </div>

                        <div className="d-none d-lg-block">
                            <img src="/static/icon.webp" style={{ width: '55px', height: '55px', borderRadius: '16px' }}  alt="icon"/>
                        </div>

                        <div className="d-none d-lg-block ms-1 me-4 px-4">
                            <span className="" style={{ fontSize: '20px', fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 700, lineHeight: '122.69%', letterSpacing: '-0.005em', color: '#FFFFFF' }}>
                                Watch Faces Gallery ®️
                            </span>
                        </div>

                        <div className="col-xxl-2 col-lg-3 col-5 col-md-3 d-flex align-items-center mx-lg-4 px-lg-4">
                            <a href="https://apps.apple.com/app/apple-store/id1643342697?pt=125193385&ct=Watch%20Faces%20Website&mt=8" className="btn w-100" onClick={install_lead}>
                                <img src="/static/download_on_the_appstore.webp" className="img-fluid"  alt="apple store"/>
                            </a>
                        </div>
                    </div>
                </div>
                {/* End Bottom Bar */}

                {/* Features Block */}
                <div className="col-12 col-lg-6 order-lg-1 mt-4 row justify-content-center text-center align-content-center">
                    <div className="col" style={{ fontFamily: 'Comfortaa', fontWeight: 700, fontSize: '25px', lineHeight: '130%',}}>
                        <span style={{ color: '#FFFFFF' }}>
                            Explore our
                        </span><br/>
                        <span style={{ color: '#FEC665' }}>
                             Watch Faces Gallery
                        </span>
                        <span> </span>
                        <span style={{ color: '#FFFFFF' }}>
                           App
                        </span>
                    </div>
                    <div className="w-100 pt-4 mt-2"></div>


                    <div className=" row mt-4">


                        <div className="col pt-3 px-0">
                            <span style={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '18px', lineHeight: '120%', color: '#000000' }}>
                                 Discover Exclusively Crafted Watch Faces
                            </span>
                        </div>
                    </div>

                    <div className=" row mt-4">


                        <div className="col pt-3 px-0">
                            {/*<span style={{ fontFamily: 'Comfortaa', fontWeight: 1000, fontSize: '30px', lineHeight: '120%', color: '#FFFFFF' }}>. </span>*/}
                            <span className="mt-3" style={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '18px', lineHeight: '120%', color: '#000000' }}>
                                  Preview Watch Faces with Ease
                            </span>
                        </div>
                    </div>

                    <div className=" row mt-4">


                        <div className="col pt-3 px-0">
                            <span className="mt-3" style={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '18px', lineHeight: '120%', color: '#000000' }}>
                                Enjoy Free and Premium Access Options
                            </span>
                        </div>
                    </div>

                    <div className=" row mt-4">


                        <div className="col pt-3 px-0">
                            <span className="mt-3" style={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '18px', lineHeight: '120%', color: '#000000' }}>

                            </span>
                        </div>
                    </div>
                    </div>
                    <div className="w-100 d-lg-none"></div>
                    <div className="mt-4 pt-4 d-lg-none">
                        <div className="row justify-content-center">
                            <div className="col col-lg-6 d-grid">
                                <a href="https://apps.apple.com/app/apple-store/id1643342697?pt=125193385&ct=Watch%20Faces%20Website&mt=8" className="btn" onClick={install_lead}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ background: '#fec665', borderRadius: '39px', height: '56px' }}>
                                        <span className="text-center" style={{ fontFamily: 'Comfortaa', fontStyle: 'normal', fontSize: '25px', color: '#000000', fontWeight: 700 }}>
                                            Install Now
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                {/*</div>*/}
                {/* End Features Block */}
            </div>
        </div>

);
}

export default FourthBlock;





















