import React from 'react';

const Footer = () => {
    return (
        <div className="container">
            <div className="row justify-content-center text-center">
                <div className="w-100 mt-4 pt-2"></div>
                <div className="col-10 col-lg-12" style={{ backgroundColor: '#DDE2E9', marginTop: '10px', height: '1px' }}>
                </div>
                <div className="col-12 mt-2" style={{ fontSize: '0.75rem' }}>
          <span className="text-center" style={{ fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 400, letterSpacing: '-0.005em', color: '#DDE2E9' }}>
            All rights reserved by metatec 2023
          </span>
                </div>
                <div className="col-12 mb-2" style={{ fontSize: '0.75rem' }}>
                    <a className="text-center" style={{ fontFamily: 'Comfortaa', fontStyle: 'normal', fontWeight: 400, letterSpacing: '-0.005em', color: '#DDE2E9' }} href="privacy-policy/">Privacy Policy</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;





