import './App.css';

import MainPage from "./pages/MainPage";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import React from "react";
import {
    BrowserRouter, Route, Routes
} from "react-router-dom";


export default function App() {




    return (
        <BrowserRouter className='hidescroll'>
            <Routes>

                    <Route index element={<MainPage />} />
                    <Route path="privacy-policy/" element={<PrivacyPolicy />} />



            </Routes>
        </BrowserRouter>
    );
}

