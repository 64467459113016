import React, {useEffect} from 'react';

const initFacebookPixel = () => {
    // eslint-disable-next-line no-unused-expressions
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', '1282200545916703');
    window.fbq('track', 'PageView', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
};

const WatchFaceButton = () => {
    useEffect(() => {
        initFacebookPixel();
    }, []);

    const install_lead = () => {
        window.fbq('track', 'Lead', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
    };

    return (
        <div className="mt-4 pt-4 pt-lg-0 container">
            <div className="row justify-content-center">
                <div className="col col-lg-5 d-grid" style={{ zIndex: 2 }}>
                    <a href="https://apps.apple.com/app/apple-store/id1643342697?pt=125193385&ct=Watch%20Faces%20Website&mt=8" className="btn" onClick={install_lead}>
                        <div className="d-flex justify-content-center align-items-center" style={{ background: '#FEC665', borderRadius: '39px', height: '60px' }}>
                            <span className="text-center" style={{ fontFamily: 'Comfortaa', fontStyle: 'normal', fontSize: '25px', color: '#000000', fontWeight: 700 }}>
                                Create Watch Face
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default WatchFaceButton;
