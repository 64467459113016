import React, {useEffect} from 'react';

const initFacebookPixel = () => {
    // eslint-disable-next-line no-unused-expressions
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', '1282200545916703');
    window.fbq('track', 'PageView', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
};

const BiggerScreen = () => {
    useEffect(() => {
        initFacebookPixel();
    }, []);

    const install_lead = () => {
        window.fbq('track', 'Lead', {external_id: '4c3e273beb0b40f4b6cd4f37f61f10bc'});
    };


    return (<>
            <div className="d-none d-lg-block container" >
                <div className="row mt-4 justify-content-center">
                    <div className="col-6 justify-content-center d-flex flex-column pt-4">
                        {/*col-5*/}
                        <div className="d-flex align-items-center pt-2 mt-4 pb-4">
                            <img src="/static/icon.webp" style={{width: '96px', height: '96px', borderRadius: '16px'}}
                                 alt="icon"/>
                            <div className="ms-2">
          <h1 style={{
              fontSize: '25px',
              fontFamily: 'Comfortaa, cursive',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '122.69%',
              letterSpacing: '-0.005em',
              color: 'gold'
          }}>
            Watch Faces Gallery ®️
          </h1>
          {/*                      <span style={{*/}
          {/*                          fontSize: '25px',*/}
          {/*                          fontFamily: 'Comfortaa, cursive',*/}
          {/*                          fontStyle: 'normal',*/}
          {/*                          fontWeight: '700',*/}
          {/*                          lineHeight: '122.69%',*/}
          {/*                          letterSpacing: '-0.005em',*/}
          {/*                          color: '#FFFFFF'*/}
          {/*                      }}>*/}

          {/*</span>*/}
                            </div>
                        </div>
                        <div style={{zIndex: 2}} className="mt-4">
                            <div className="mt-4">
          <span style={{
              fontSize: '18px',
              fontFamily: 'Comfortaa, cursive',
              fontStyle: 'normal',
              fontWeight: '400',
              color: '#FFFFFF'
          }}>
           Introducing the Latest Apple Watch Faces for a Fresh Look!
              <br/> <br/>
              Customize your Apple Watch Face with our Extensive Collection of Unique Designs and Complications.
              <br/> <br/>
              Our App seamlessly works with every Apple Watch model.
              <br/> <br/>
                  Discover now!
          </span>
                            </div>
                            <div className="pt-4 row justify-content-start mx-0" style={{zIndex: 2}}>
                                <div className="col-5 px-0">
                                    <a href="https://apps.apple.com/us/app/watch-faces-gallery/id1643342697"
                                       className="btn w-100 px-0" onClick={install_lead}>
                                        <img src="/static/download_on_the_appstore.webp" className="img-fluid" alt="apple_store"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5" style={{zIndex: 2}}>
                        <div id="mobile-watch" onClick={() => {
                            window.location.href = 'https://apps.apple.com/us/app/watch-faces-gallery/id1643342697';
                        }}>
                            {/* Will be replaced for image when width lg */}
                            {/* <img src="/static/iPhone_Watch.webp" className="img-fluid" /> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-3 row justify-content-center mt-lg-4 pt-lg-4">
                <div className="mt-lg-4">
                    <div className="col text-center" style={{
                        fontFamily: 'Comfortaa, cursive',
                        color: '#FFFFFF',
                        fontSize: '25px',
                        lineHeight: '120%',
                        fontWeight: 300
                    }}>
                        Personalize Your
                    </div>
                    <div className="w-100"></div>
                    <div className="col text-center" style={{
                        fontFamily: 'Comfortaa, cursive',
                        fontStyle: 'normal',
                        fontSize: '25px',
                        color: '#FFFFFF',
                        fontWeight: 700
                    }}>
                        Apple Watch Face
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none">
                <div className="pt-4 row justify-content-center">
                    <div className="col-lg-3 col-9 col-md-6" style={{zIndex: 1}}>
                        <a href="https://apps.apple.com/us/app/watch-faces-gallery/id1643342697" className="btn w-100"
                           onClick={install_lead}>
                            <img src="/static/download_on_the_appstore.webp" className="img-fluid" alt="apple_store"/>
                        </a>
                    </div>
                </div>
            </div>

        </>);
};

export default BiggerScreen;
