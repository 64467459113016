import React from 'react';


const Mobile = () => {
    const handleOnClick = () => {
        window.location.href = 'https://apps.apple.com/app/apple-store/id1643342697?pt=125193385&ct=Watch%20Faces%20Website&mt=8';
    };
    return (

        <div className="container-fluid pt-4 pb-2">
            <div className="d-lg-none row justify-content-center pt-4">
                <div className="row justify-content-center mx-0 px-0" onClick={handleOnClick}>
                    <div className="col-8 col-md-4 text-center mx-0 px-0" style={{ position: 'relative', zIndex: 2 }}>
                        <img src="/static/updated-index/iPhone-Watch_cr.png" className="d-block w-100" style={{ filter: 'drop-shadow(6px 9px 45px #8506EA)' }} alt="iphone and watch" />
                    </div>
                </div>
            </div>
            </div>
    );
};

export default Mobile;
